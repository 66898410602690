import {
  Box,
  Link,
  Typography,
  TextareaAutosize,
  Alert,
  Snackbar,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Api from "../../lib/api";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "../Layout/Button";
import ImageList from "../Images/ImageList";
import IconButton from "../Layout/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoadingSpinner from "../Layout/LoadingSpinner";
import SelectImagePopup from "../Images/SelectImagePopup";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {},
  questionsContainer: {
    marginTop: 10,
    marginBottom: 50,
  },
  questionLink: { wordBreak: "break-all" },
  questionItems: {
    marginBottom: 20,
  },
  questionText: {
    fontWeight: "500 !important",
    marginBottom: "5px !important",
  },
  questionTextSpan: {
    color: "rgb(76, 175, 80)",
  },
  answer: {},
  templateChatGtp: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textArea: {
    width: "100%",
    resize: "vertical",
    border: "1px solid #ccc",
    borderRadius: "4px",
    outline: "none",
    marginBottom: "30px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.43,
    padding: 30,
  },
}));

const Form = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();
  const { formId } = useParams();
  const [form, setForm] = useState({});
  const [chatGtpText, setChatGtpText] = useState("");
  const [header, setHeader] = useState(null);
  const [footer, setFooter] = useState(null);
  const [autoComplete, setAutoComplete] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePopupSeleted, setImagePopupSeleted] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchForm = (id) => {
    Api.formById(id)
      .then((response) => {
        setForm(response);
        setIsLoading(false);
        setSelectedImages(response.images);
        setHeader(response.header);
        setFooter(response.footer);
        setAutoComplete(response.autocomplete);
        if (response.gptTemplate) {
          setChatGtpText(response.gptTemplate);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setHttpError(error.message);
        console.log("Error fetching forms:", error);
      });
  };

  useEffect(() => {
    fetchForm(formId);
  }, [formId]);

  const onChangeChatGtp = (e) => {
    setChatGtpText(e.target.value);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (httpError) {
    return <div>Error: {httpError}</div>;
  }

  const handleClickChatGtp = () => {
    const imageIds = selectedImages.map((item) => item.id);
    Api.updateForm(
      formId,
      autoComplete,
      header,
      footer,
      chatGtpText,
      imageIds
    ).then((response) => {
      setChatGtpText(response.gptTemplate);
      setSelectedImages(response.images);
      setHeader(response.header);
      setFooter(response.footer);
      setAutoComplete(response.autocomplete);
      handleOpen();
    });
  };

  const handleImageDelete = (itemId) => {
    const updatedImages = selectedImages.filter((image) => image.id !== itemId);
    setSelectedImages(updatedImages);
  };

  const onHoveredBlock = (hoveredBlock, itemId) => {
    if (hoveredBlock === itemId) {
      return (
        <IconButton onClick={() => handleImageDelete(itemId)} color="red">
          <HighlightOffIcon style={{ fontSize: "inherit" }} />
        </IconButton>
      );
    }
  };

  const handlImagePopupSeleted = () => {
    setImagePopupSeleted(true);
  };

  const handlImagePopupClosed = (data) => {
    setSelectedImages(data);
    console.log(selectedImages);
    setImagePopupSeleted(false);
  };

  const handleBackButton = () => {
    navigate("/forms");
  };

  return (
    <Box className={classes.container}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message="Invalid form link"
      >
        <Alert severity="success" onClose={handleClose}>
          Успешно!
        </Alert>
      </Snackbar>
      <Typography variant="h6">{form.title}</Typography>
      <Link href={form.link} target="_blank" className={classes.questionLink}>
        <Typography variant="body2">{form.link}</Typography>
      </Link>
      <Box className={classes.questionsContainer}>
        {form &&
          form.questions &&
          form.questions.map((question) => (
            <Box key={question.id} className={classes.questionItems}>
              <Typography className={classes.questionText} variant="body1">
                <span className={classes.questionTextSpan}>
                  {" "}
                  {question.variable}.
                </span>{" "}
                {question.question}
              </Typography>
              {question &&
                question.answers &&
                question.answers.map((answer, index) => (
                  <Box key={answer.id} className={classes.answer}>
                    <Typography variant="body2">
                      {index + 1}. {answer.answer}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ))}
      </Box>

      <Box className={classes.mailFields}>
        <FormControlLabel
          control={
            <Checkbox
              checked={autoComplete}
              onChange={(event) => setAutoComplete(event.target.checked)}
              color="primary"
            />
          }
          sx={{ width: "70%", marginBottom: 3 }}
          label="Авто-ответ пользователю"
        />
        <TextField
          id="header"
          label="Хедер:"
          variant="outlined"
          fullWidth
          sx={{ width: "100%", marginBottom: 3 }}
          value={header}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setHeader(event.target.value)}
        />
        <TextField
          id="footer"
          label="Футер:"
          variant="outlined"
          fullWidth
          sx={{ width: "100%", marginBottom: 3 }}
          value={footer}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setFooter(event.target.value)}
        />
      </Box>
      <Box className={classes.templateChatGtp}>
        <Typography className={classes.questionText} variant="body1">
          Шаблон для ChatGPT
        </Typography>
        <TextareaAutosize
          className={classes.textArea}
          value={chatGtpText}
          onChange={onChangeChatGtp}
        />
        <Grid container spasing={2}>
          <Grid item md={8}>
            <Button onClick={handlImagePopupSeleted}>
              Добавить изображения
            </Button>
            {imagePopupSeleted && (
              <SelectImagePopup
                previousSelectedImages={selectedImages}
                open={imagePopupSeleted}
                handleClose={handlImagePopupClosed}
              />
            )}
          </Grid>
          <Grid item md={2}>
            <Button onClick={handleBackButton}>Назад</Button>
          </Grid>
          <Grid item md={2}>
            <Button onClick={handleClickChatGtp} className={classes.button}>
              Сохранить
            </Button>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "30px" }}>
          <ImageList images={selectedImages} onHoveredBlock={onHoveredBlock} />
        </Box>
      </Box>
    </Box>
  );
};

export default Form;
