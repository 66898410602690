import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';


const Header = ({classes, toggleSidebar}) => {

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/logout");
  }


  return (
    <AppBar position="relative" className={classes.appBar} style={{height:55}}>
      <Toolbar style={{ justifyContent: 'space-between', minHeight:55 }}>
        <IconButton style={{display:'flex', width: 'auto', padding: 0}} onClick={toggleSidebar}>
          <MenuIcon style={{ color: 'white', borderRadius: "5px", fontSize: '2.3rem'}}/>
        </IconButton>
        <Typography variant="h4" className={classes.title} color="white">
          ДИЕТЫ
        </Typography>
        <Button style={{ backgroundColor: 'white' }} onClick={handleLogout}>Выйти</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;