import { useEffect, useState } from 'react';
import { Typography, TextField, Box, Checkbox, FormControlLabel } from '@mui/material';
import Api from '../../lib/api';
import LoadingSpinner from '../Layout/LoadingSpinner';
import Button from '../Layout/Button';

const Settings = (props) => {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [autoComplete, setAutoComplete] = useState(false);

  const fetchSettings = () => {
    Api.listSettings()
      .then(response => {
        console.log(response)
        setSettings(response);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log('Error fetching settings:', error);
      })
  }

  useEffect(() => {
    fetchSettings();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  const handleUpdate = (key, value) => {
    const element = settings.find(item => item.key === key);
    if (!element) return;
    let updatedSettings = settings.filter(item => item.key !== key);
    updatedSettings.push({ ...element, value: value });
    setSettings(updatedSettings);
  }

  const findSettings = (key) => {
    const searchedSettings = settings.find(item => item.key === key);
    return searchedSettings.value;
  }

  const handleSave = () => {
    const body = {
      updated_settings: settings 
    }
    Api.updateSettings(body)
  }

  return (
    <Box sx={{ '& > :not(style)': { mb: "10px" } }}>
      <Button
        style={{ display: "flex" }}
        onClick={handleSave}
      >
        Сохранить
      </Button>
    </Box>
  );
};

export default Settings;