import React from 'react';
import { Box } from '@mui/material';
import Button from '../Layout/Button';

function ImageUploader({ handleImageUpload }) {
    return (
        <Box style={{
            marginBottom: 15, display: 'flex',
            flexDirection: "row",
            justifyContent: "center",
        }}>
            <label htmlFor="uploadButton">
                <Button variant="contained" component="span">
                    Добавить изображения
                </Button>
            </label>
            <input type='file' multiple id="uploadButton" onChange={handleImageUpload} style={{ display: "none", }} />
        </Box>
    );
}

export default ImageUploader;