import Api from '../../lib/api';
import { TextField, Snackbar, Alert, Grid, useTheme } from '@mui/material';
import Button from '../Layout/Button';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center",
        paddingBottom: 10,
        width: '100%'
    },
    linkInput: {
        width: '100%'
    },
}));

const AddForm = ({ formAddedHandler }) => {
    const theme = useTheme();

    const smUp = useMediaQuery(theme.breakpoints.up('sm'));

    const classes = useStyles();
    const [formId, setFormId] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setMessage("")
        setOpen(false);
    };


    const handleChange = event => {
        setFormId(event.target.value);
    };

    const handleClick = () => {
        if (formId === "") {
            setMessage('ID формы не может быть пустым!')
            handleOpen()
        } else {
            Api.addForm(formId)
            .then(response => {
                if (response.status === "INTERNAL_SERVER_ERROR") {
                    setMessage('Форма не найдена!')
                    handleOpen()
                } else if(response.status === "CONFLICT") {
                    setMessage('Форма уже существует!')
                    handleOpen()
                }
                else {
                    formAddedHandler(response);
                }
            })
            .catch(error => {
                console.log('Failed to add form:', error);
            })
        }
    };

    return (

        <Grid container spacing={1} className={classes.container}>
            <Grid item xs={12} sm={10}>
                <TextField
                    id="addForm"
                    label="ID формы"
                    variant="outlined"
                    fullWidth
                    className={classes.linkInput}
                    value={formId}
                    onChange={handleChange}
                />
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} message="Invalid form id">
                    <Alert severity="error" onClose={handleClose}>{message}</Alert>
                </Snackbar>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Button onClick={handleClick} className={classes.button} style={{ width: '100%', height: 56 }}>Добавить</Button>
            </Grid>
        </Grid>
    );
}

export default AddForm;