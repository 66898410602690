import React from 'react';
import Sidebar from "./Sidebar";
import { Box, Grid, useTheme } from '@mui/material';
import Header from './Header'
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: '#4CAF50',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
    },
    content: {
        height: 'calc(100vh - 55px)',
        marginTop: 0
    },
    pageContainder: {
        marginTop: 55
    },
    body: {
        display: "flex",
        marginTop: '0px !important',
    }
}));

const theme = createTheme({
    spacing: 8,
    palette: {
        primary: {
            main: '#4CAF50',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    link: {
    }
});

function Layout() {
    const classes = useStyles();
    const mobileTheme = useTheme();
    const mdUp = useMediaQuery(mobileTheme.breakpoints.up('md'));
    const [showSidebar, setShowSidebar] = useState(true);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className={classes.content}>
                <Header classes={classes} toggleSidebar={toggleSidebar} />

                <Grid container className={classes.body} style={{height: mdUp ? 'calc(100vh - 55px)' : "auto",}}>

                    {showSidebar && (
                        <Grid item xs={12} md={3} lg={2} style={{
                            backgroundColor: "#4CAF50",
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
                        }}>
                            <Sidebar />
                        </Grid>
                    )
                    }
                    <Grid
                        item
                        xs={12}
                        md={showSidebar ? 9 : 12}
                        lg={showSidebar ? 10 : 12}
                        style={{padding: "10px 10px 0", overflow: 'auto', height: mdUp ? "calc(100vh - 55px)" : "auto"}}
                    >
                        <Outlet />
                    </Grid>
                </Grid>
            </Box>

        </ThemeProvider>
    );
}

export default Layout;