import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Container } from '@mui/material';
import Login from '../../components/Login';
import useSession, { login } from '../../hooks/session';
import { Snackbar, Alert } from '@mui/material';

export default function LoginView() {
  const navigate = useNavigate();
  const session = useSession();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (session) {
    return <Navigate to="/" replace />;
  }

  const authenticate = async ({ username, password }) => {
    login({ username, password })
      .then(() => navigate('/'))
      .catch((error) => {
        handleOpen();
      });
  }


  return (
    <Container maxWidth="sm" sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} message="Invalid login or password" anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
        <Alert severity="error" onClose={handleClose}>Invalid login or password</Alert>
      </Snackbar>
      <Login onLogin={authenticate} />
    </Container>
  );
}