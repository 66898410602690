import { Typography, Grid, Link, IconButton, Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Api from "../../lib/api";
import AddForm from "./AddForm";
import SyncIcon from "@mui/icons-material/Sync";
import DeleteIcon from "@mui/icons-material/Delete";
import InputIcon from '@mui/icons-material/Input';

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
  },
  listContainer: {
    border: "1px solid #cfcccc",
    borderRadius: 4,
  },
  table: {
    align: "center",
    wordBreak: "break-all",
  },
  tableHeadCell: {
    display : "grid",
    placeContent: "center",
    padding: "5px 12px",
    fontWeight: "bold",
    background: "#F7F9FC",
    color: "rgb(97, 108, 122)",
    borderRight: "1px solid #cfcccc",
  },
  tableCell: {
    borderRight: "1px solid #cfcccc",
    display : "grid",
    placeContent: "center",
    verticalAlign: "middle",
    padding: 3
  },
  googleFormLink: {
    color: "black !important",
  },
  formLink: {
    cursor: "pointer",
  },
  headerContainer: {
    borderBottom: "2px solid #cfcccc",
  },
  tableCellItem: {
    borderBottom: "1px solid #cfcccc",
  },
  refreshAction: {
    color: "green",
  },
  deleteAction: {
    color: "red",
  },
  actionCell: {
    padding: 3,
    display: "table-cell",
    textAlign: "center"
  }
}));

const Forms = () => {
  const navigate = useNavigate();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [forms, setForms] = useState([]);
  const [httpError, setHttpError] = useState();

  const fetchForms = () =>
    Api.forms()
      .then((response) => {
        setForms(response);
      })
      .catch((error) => {
        setHttpError(error.message);
        console.log("Error fetching forms:", error);
      })
      .finally(() => setIsLoading(false));

  useEffect(() => {
    fetchForms();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (httpError) {
    return <div>Error: {httpError}</div>;
  }

  const formAddedHandler = (addFormResponse) => {
    setForms((prevForms) => [...prevForms, addFormResponse]);
  };

  const handleFormSelected = (formId) => {
    navigate(`/forms/${formId}`);
  };

  const handleRefresh = async (formId, index) => {
    const response = await Api.refreshForm(formId);

    const updatedForms = [...forms];
    updatedForms[index] = response;

    setForms(updatedForms);
  };

  const handleDelete = (formId) => {
    Api.deleteForm(formId);

    const updatedForms = forms.filter((form) => form.id !== formId);
    setForms(updatedForms);
  };

  const handlAutocompleteChecked = async (formId, index, event) => {
    const response = await Api.autocompleteForm(formId, event.target.checked);
    const updatedForms = [...forms];
    const formToUpdate = forms.find(form => form.id === formId);
    formToUpdate.autocomplete = response.autocomplete;
    updatedForms[index] = formToUpdate;

    setForms(updatedForms);
  };

  const handleIncomingClicked = (formId) => {
    navigate(`/incoming/${formId}`);
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <AddForm formAddedHandler={formAddedHandler} />
      </Grid>
      <Grid item xs={12} className={classes.listContainer}>
        <Grid container className={classes.table}>
          <Grid container className={classes.headerContainer}>
            <Grid item xs={2} className={classes.tableHeadCell}>
              <Typography>Название</Typography>
            </Grid>
            <Grid item xs={0.5} className={classes.tableHeadCell}>
              <Typography>ID</Typography>
            </Grid>
            <Grid
              item
              xs={5.5}
              className={`${classes.tableHeadCell} ${classes.columnLink}`}
            >
              <Typography>Ссылка</Typography>
            </Grid>
            <Grid item xs={1} className={classes.tableHeadCell}>
              <Typography>Входящие</Typography>
            </Grid>
            <Grid item xs={1} className={classes.tableHeadCell}>
              <Typography>Авто-ответ</Typography>
            </Grid>
            <Grid item xs={1} className={classes.tableHeadCell}>
              <Typography>Кол-во переменных</Typography>
            </Grid>
            <Grid item xs={1} className={classes.tableHeadCell}>
              <Typography>Действия</Typography>
            </Grid>
          </Grid>
          <Grid container>
            {forms.map((item, index) => {
              return (
                <Grid container key={index} className={classes.tableCellItem}>
                  <Grid item xs={2} className={classes.tableCell}>
                    <Link
                      onClick={() => handleFormSelected(item.id)}
                      className={classes.formLink}
                      underline="none"
                    >
                      <Typography>{item.title} </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={0.5} className={classes.tableCell}>
                    <Typography>{item.id} </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={5.5}
                    className={`${classes.tableCell} ${classes.columnLink}`}
                  >
                    <Link
                      href={item.link}
                      underline="none"
                      className={classes.googleFormLink}
                    >
                      <Typography>{item.link}</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                  <IconButton
                      style={{ color: "#4CAF50" }}
                      onClick={() => handleIncomingClicked(item.id)}
                    >
                      <InputIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    <Checkbox
                      checked={item.autocomplete}
                      onChange={(event) =>
                        handlAutocompleteChecked(item.id, index, event)
                      }
                      color="primary"
                    />
                  </Grid>
                  <Grid item xs={1} className={classes.tableCell}>
                    <Typography>{item.countPrompts}</Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.actionCell}>
                    <IconButton
                      style={{ color: "green" }}
                      onClick={() => handleRefresh(item.id, index)}
                    >
                      <SyncIcon />
                    </IconButton>
                    <IconButton
                      style={{ color: "#c91212" }}
                      onClick={() => handleDelete(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Forms;
