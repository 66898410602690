import { ImageList as  ImageListItem, Typography, Box } from "@mui/material";
import { useState } from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    imageListContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    imageItem: {
        marginBottom: 30,
        marginLeft: 15,
        position: "relative",
    },
    image: {
        height: 200,
        width: 200,
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
        objectFit: "cover"
    }
}));

const ImageList = ({ images, selectedImages, onHoveredBlock, onHoveredBlockSelect, onHoveredBlockUnselect }) => {
    const classes = useStyles();
    const [hoveredBlock, setHoveredBlock] = useState(null);

    const handleMouseEnter = (blockId) => {
        setHoveredBlock(blockId);
    };

    const handleMouseLeave = () => {
        setHoveredBlock(null);
    }

    return (
        <Box className={classes.imageListContainer}>
            {images.map((item) => (
                <Box className={classes.imageItem} key={item.img} onMouseEnter={() => handleMouseEnter(item.id)}
                    onMouseLeave={handleMouseLeave}>
                    <img
                        src={`data:image/jpeg;base64,${item.content}`}
                        alt={item.filename}
                        className={classes.image}
                        loading="lazy"
                    />
                    {onHoveredBlock && onHoveredBlock(hoveredBlock, item.id)}
                    {onHoveredBlockSelect && onHoveredBlockSelect(selectedImages, hoveredBlock, item)}
                    {onHoveredBlockUnselect && onHoveredBlockUnselect(selectedImages, item)}
                    <Typography style={{ textAlign: "center" }}>{item.filename}</Typography>
                </Box>
            ))}
        </Box>
    );
}

export default ImageList;