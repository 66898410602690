import { useEffect, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box } from '@mui/material';
import Api from '../../lib/api';
import IconButton from '../Layout/IconButton';
import ImageUploader from './ImageUploader';
import ImageList from './ImageList';

const Images = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();
  const [images, setImages] = useState([]);


  const addImagesToDefaultImages = async (selectedImages) => {
    const fetchedImages = JSON.parse(await Api.addImages(selectedImages));
    setImages(prevImages => [...prevImages, ...fetchedImages]);
    return fetchedImages;
  }

  const fetchImages = () =>
    Api.listImages()
      .then(response => {
        setImages(response);
      })
      .catch(error => {
        setHttpError(error.message);
        console.log('Error fetching images:', error);
      }).finally(() => setIsLoading(false));

  useEffect(() => {
    fetchImages();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (httpError) {
    return <div>Error: {httpError}</div>;
  }

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    addImagesToDefaultImages(files);
  };

  const handleImageDelete = async (id) => {
    const updatedImages = images.filter((image) => image.id !== id);
    Api.deleteImage(id);

    setImages(updatedImages);
  }

  const onHoveredBlock = (hoveredBlock, itemId) => {
    if (hoveredBlock === itemId) {
      return <IconButton onClick={() => handleImageDelete(itemId)} color='red'>
        <HighlightOffIcon style={{ fontSize: 'inherit' }} />
      </IconButton>
    }
  }

  return (
    <Box>
      <ImageUploader handleImageUpload={handleImageUpload} />
      <ImageList images={images} onHoveredBlock={onHoveredBlock} />
    </Box>
  );
};

export default Images;