import { useState } from 'react';
import { createTheme, ThemeProvider, TextField, Button, Box } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4CAF50',
        },
    },
});

export default function Login({ onLogin }) {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });


    const handleChange = (event) => {
        setFormData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const submitHandler = (event) => {
        event.preventDefault();
        return onLogin(formData);
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%', p: 3, borderRadius: 1, bgcolor: 'background.paper', boxShadow: 1 }}>
                <form onSubmit={submitHandler}>
                    <TextField
                        label="Имя пользователя"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />

                    <TextField
                        label="Пароль"
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    <Button variant="contained" type="submit" fullWidth sx={{
                        mt: 2,
                        bgcolor: '#4CAF50',
                        color: '#FFFFFF',
                        '&:hover': {
                            bgcolor: '#388E3C',
                        },
                    }}>Войти</Button>
                </form>
            </Box>
        </ThemeProvider>
    );
}