import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Api from '../../lib/api';
import Button from '../Layout/Button';
import IconButton from '../Layout/IconButton';
import ImageList from './ImageList';

const SelectedImages = ({ previousSelectedImages, open, handleClose }) => {

    const [images, setImages] = useState([]);
    const [selectedImages, setSetlectedImages] = useState(previousSelectedImages);

    const fetchImages = () => {
        Api.listImages()
            .then(response => {
                setImages(response);
            })
            .catch(error => {
                console.log('Error fetching images:', error);
            })
    }

    useEffect(() => {
        fetchImages();
    }, []);

    const handlePopupClosed = () => {
        handleClose(selectedImages);
    }

    const handleImageSelected = (image) => {
        setSetlectedImages([...selectedImages, image])
    }

    const handleImageUnselect = (image) => {
        const updatedImages = selectedImages.filter((selectedImage) => selectedImage.filename !== image.filename);
        setSetlectedImages(updatedImages);
    }

    const onHoveredBlockSelect = (selectedItems, hoveredBlock, item) => {
        if (!(selectedItems.some((selectedItem) => selectedItem.id === item.id)) && hoveredBlock === item.id) {
            return <IconButton onClick={() => handleImageSelected(item)} color='#4CAF50'>
                <AddCircleOutlineIcon style={{ fontSize: 'inherit' }} />
            </IconButton>
        }
    }

    const onHoveredBlockUnselect = (selectedItems, item) => {
        if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
            return <IconButton color='#4CAF50'
                onClick={() => handleImageUnselect(item)}>
                <CheckCircleOutlineIcon style={{ fontSize: 'inherit' }} />
            </IconButton>
        }
    }


    return (
        <Dialog open={open} onClose={() => handleClose(selectedImages)} maxWidth="md" fullWidth>
            <DialogTitle>Изображения</DialogTitle>
            <DialogContent style={{padding: 15}}>
                <ImageList
                    images={images}
                    selectedImages={selectedImages}
                    onHoveredBlockSelect={onHoveredBlockSelect}
                    onHoveredBlockUnselect={onHoveredBlockUnselect} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePopupClosed}>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SelectedImages