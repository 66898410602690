import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';

const generateTable = (questions) => {
    return questions.map((question) => {
        return (
            <TableRow key={question.id}>
            <TableCell>{question.question}</TableCell>
            <TableCell>{question.answer}</TableCell>
          </TableRow>
        )
    });
  };

const QuestionTable = ({ questions }) => {
    return (
        <TableContainer component={Paper} style={{width: "100%"}}>
          <Table>
            <TableBody>
              {generateTable(questions)}
            </TableBody>
          </Table>
        </TableContainer>
      );
}

export default QuestionTable;