import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IconButton, Typography, Grid, Box } from "@mui/material";
import Api from "../../lib/api";

const Incoming = () => {
  const [forms, setForms] = useState([]);
  const { formId } = useParams();
  
  const fetchIncoming = () => {
    if (formId) {
      Api.listInputFormsById(formId).then((response) => {
        setForms(response);
      })
      .catch((error) => {
        console.log("Error fetching forms:", error);
      });
    } else {
      Api.listInputForms()
      .then((response) => {
        setForms(response);
      })
      .catch((error) => {
        console.log("Error fetching forms:", error);
      });
    }
  };

  useEffect(() => {
    fetchIncoming();
  }, [formId]);

  return (
    <div style={{ width: "100%" }}>
      {forms.map((item) => {
        return (
          <IncomingItem
            email={item.mailPerformed}
            receivedDate={item.receivedAt}
            id={item.id}
            status={item.formStatus}
          />
        );
      })}
    </div>
  );
};

export default Incoming;

const IncomingItem = ({ email, receivedDate, id, status }) => {
  const navigate = useNavigate();

  const handleOutgoingSelected = (formId) => {
    navigate(`/diet/${formId}`);
  };

  return (
    <Box>
      <Grid
        container
        spacing={3}
        style={{
          borderBottom: "1px solid black",
          padding: 5,
        }}
      >
        <Grid item md={4}>
          <Typography style={{ paddingTop: "8px", paddingLeft: 10 }}>
            {email}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography style={{ paddingTop: "8px" }}>{receivedDate}</Typography>
        </Grid>
        <Grid item md={3.5}>
          <Typography style={{ paddingTop: "8px" }}>{status}</Typography>
        </Grid>
        <Grid item md={0.5}>
          <IconButton
            style={{ color: "#4CAF50" }}
            onClick={() => handleOutgoingSelected(id)}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
