import { createContext, useCallback, useState, useContext, useLayoutEffect } from 'react';
import Api from '../lib/api';

const SESSION = 'session';

const SessionContext = createContext(null);

let setContextValue = () => { };

export const Provider = ({ children }) => {
    const [state, setState] = useState(getSession());

    useLayoutEffect(() => {
        Api.configure({ accessToken: state?.accessToken });
    }, [state])

    setContextValue = useCallback(
        (value) => setState(value),
        [setState]
    );

    return (
        <SessionContext.Provider value={state}>
            {children}
        </SessionContext.Provider>
    );
}

export default function useSession() {
    return useContext(SessionContext);
};

export async function login({ username, password }) {
    const response = await Api.authenticate(username, password);
    if (response.token) {
    console.log(response);
        const session = {
            username: response.username,
            accessToken: response.token
        };

        setSession(session);
        setContextValue(session);
    }
}

export async function logout() {
    removeSession();
    setContextValue(undefined);
}

function getSession() {
    return JSON.parse(localStorage.getItem(SESSION));
}

function setSession(session) {
    localStorage.setItem(SESSION, JSON.stringify(session));
}

function removeSession() {
    localStorage.removeItem(SESSION);
}