import { IconButton as MuiIconButton } from "@mui/material";

const IconButton = (props) => {
    const { color: color, style: additionalStyles, ...otherProps } = props;
    const mergedStyles = {
        position: 'absolute',
        top: '-20px',
        right: '-20px',
        backgroundColor: 'transparent',
        color: color,
        fontSize: '32px',
        ...additionalStyles,
    };

    return <MuiIconButton {...otherProps} style={mergedStyles} />;
};

export default IconButton;