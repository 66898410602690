import { Button as MuiButton } from "@mui/material";

const Button = (props) => {
  const { style: additionalStyles, disabled, ...otherProps } = props;

  const mergedStyles = {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "1px #4CAF50",
    fontSize: "1rem",
    padding: '10px 20px',
    opacity: disabled ? 0.65 : 1,
    ...additionalStyles,
  };

  return <MuiButton {...otherProps} style={mergedStyles} disabled={disabled} />;
};

export default Button;