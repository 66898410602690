import { logout } from "../hooks/session";

// const BASE_URL = process.env.REACT_APP_BASE_URL || "https://green-fit-diet.pp.ua";
const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080'

class Api {
  #accessToken;

  configure({ accessToken }) {
    this.#accessToken = accessToken;
  }

  authenticate(username, password) {
    return this.#request("/api/authentication/authenticate", {
      method: "POST",
      body: { username, password },
    });
  }

  listInputForms() {
    return this.#request("/api/form-results", {
      method: "GET",
    });
  }

  listInputFormsById(formId) {
    return this.#request(`/api/form-results/filter/${formId}`, {
      method: "GET",
    });
  }

  inputForm(formId) {
    return this.#request("/api/form-results/" + formId, {
      method: "GET",
    });
  }

  listSettings() {
    return this.#request("/api/settings", {
      method: "GET",
    });
  }

  updateSettings(body) {
    return this.#request("/api/settings", {
      method: "POST",
      body: body,
    });
  }

  listImages() {
    return this.#request("/api/images", {
      method: "GET",
    });
  }

  forms() {
    return this.#request("/api/form", {
      method: "GET",
    });
  }

  addForm(formId) {
    return this.#request("/api/form", {
      method: "POST",
      body: { googleFormId: formId },
    });
  }

  formById(id) {
    return this.#request("/api/form/" + id, {
      method: "GET",
    });
  }

  async addImages(selectedImages) {
    const formData = new FormData();

    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("files", selectedImages[i]);
    }
    const url = BASE_URL + "/api/images";

    const headers = {};

    if (this.#accessToken) {
      headers.Authorization = this.#accessToken;
    }

    const reponse = await fetch(url, {
      method: "POST",
      headers,
      body: formData,
    });
    const json = await reponse.text();
    return json;
  }

  sendFormResults(id, message, imageIds) {
    console.log(message);
    return this.#request("/api/form-results/send", {
      method: "POST",
      body: {
        id: id,
        message: message,
        imageIds: imageIds,
      },
    });
  }

  saveFormResults(id, message, imageIds) {
    return this.#request("/api/form-results/save", {
      method: "POST",
      body: { id, message, imageIds },
    });
  }

  deleteImage(filename) {
    return this.#request("/api/images/" + filename, {
      method: "DELETE",
    });
  }
  updateForm(id, autocomplete, header, footer, text, imageIds) {
    return this.#request(`/api/form/${id}`, {
      method: "PUT",
      body: {
        header: header,
        footer: footer,
        gptTemplate: text,
        imageIds: imageIds,
        autocomplete: autocomplete,
      },
    });
  }

  deleteForm(id) {
    return this.#request(`/api/form/${id}`, {
      method: "DELETE",
    });
  }

  refreshForm(id) {
    return this.#request(`/api/form/refresh/${id}`, {
      method: "PUT",
    });
  }

  autocompleteForm(id, value) {
    return this.#request(`/api/form/autocomplete?id=${id}&value=${value}`, {
      method: "PUT",
    });
  }

  #request(path, { method, body }) {
    const url = BASE_URL + path;

    const headers = {
      "Content-Type": "application/json;  charset=utf-8",
      "Accept-Charset": "utf-8",
    };

    if (this.#accessToken) {
      headers.Authorization = this.#accessToken;
    }

    console.log(JSON.stringify(body));

    return fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    }).then((response) =>
      response.text().then((responseText) => {
        let responseJSON = null;
        try {
            responseJSON = JSON.parse(responseText);
        } catch (e) { }

        if (response.ok) {
            return responseJSON;
        }

        if (response.status === 401) {
          logout();
          throw responseJSON;
        }
        return JSON.parse(responseText);
      })
    );
  }
}

export default window._api_client = new Api();
