import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TextEditor from './TextEditor';
import { useNavigate } from 'react-router-dom';
import SelectImagePopup from '../Images/SelectImagePopup'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import QuestionTable from './QuestionTable';
import Api from '../../lib/api';
import Button from '../Layout/Button';
import IconButton from '../Layout/IconButton';
import ImageList from '../Images/ImageList';
import { Grid } from '@mui/material';
import LoadingSpinner from '../Layout/LoadingSpinner';

const Diet = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();
  const { formId } = useParams();
  const [form, setForm] = useState({});
  const navigate = useNavigate();
  const [imagePopupSeleted, setImagePopupSeleted] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [editorContent, setEditorContent] = useState('');

  const handlImagePopupSeleted = () => {
    setImagePopupSeleted(true);
  };

  const handlImagePopupClosed = (data) => {
    setSelectedImages(data);
    console.log(selectedImages)
    setImagePopupSeleted(false);
  };

  useEffect(() => {
    const fetchForm = (id) => {
      Api.inputForm(id)
        .then(response => {
          setForm(response);
          setEditorContent(response.gptSolution);
          setIsLoading(false);
          setSelectedImages(response.images);
        })
        .catch(error => {
          setIsLoading(false);
          setHttpError(error.message);
          console.log('Error fetching forms:', error);
        })
    }

    fetchForm(formId);
  }, [formId]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (httpError) {
    return <div>Error: {httpError}</div>;
  }


  const handleBackButton = () => {
    navigate('/incoming')
  }

  const handleImageDelete = (itemId) => {
    const updatedImages = selectedImages.filter((image) => image.id !== itemId);
    setSelectedImages(updatedImages);
  }

  const handleSend = async () => {
    const imageIds = selectedImages.map(image => image.id);
    console.log(imageIds);

    await Api.sendFormResults(
      form.id,
      editorContent,
      imageIds
    );

    navigate('/incoming')
  }

  const handleSave = () => {
    Api.saveFormResults(
      form.id,
      editorContent,
      selectedImages.map(image => image.id)
    );
  }

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const onHoveredBlock = (hoveredBlock, itemId) => {
    if (hoveredBlock === itemId) {
      return <IconButton onClick={() => handleImageDelete(itemId)} color='red'>
        <HighlightOffIcon style={{ fontSize: 'inherit' }} />
      </IconButton>
    }
  }

  return (
    <Grid container spacing={4} style={{padding: "3%"}}>
      <Grid item xs={12}  >
        <QuestionTable questions={form.questions} />
      </Grid>
      <Grid item xs={12}>
        <TextEditor defaultText={form.gptSolution} borderCollapse="collapse" onChange={handleEditorChange} />
      </Grid>
      <Grid container spacing={3}  style={{padding: "2%"}}>
        <Grid item md={4}>
            <Button onClick={handlImagePopupSeleted}>
              Добавить изображения
            </Button>
            {imagePopupSeleted &&
              <SelectImagePopup previousSelectedImages={selectedImages} open={imagePopupSeleted} handleClose={handlImagePopupClosed} />}
        </Grid>
        <Grid item md={4}></Grid>
        <Grid item md={4} style={{display: "flex", "justifyContent": "flex-end"}}>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Button onClick={handleBackButton}>
                Назад
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button onClick={handleSave}>
                Сохранить
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button onClick={handleSend}>
                Отправить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ImageList images={selectedImages} onHoveredBlock={onHoveredBlock} />
      </Grid>
    </Grid>
  );
};

export default Diet;