import InputIcon from '@mui/icons-material/Input';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const SidebarItems = [
    {
        id: 1,
        name: "Входящие",
        route: '/incoming',
        icon: <InputIcon />
    },
    {
        id: 2,
        name: "Изображения",
        route: '/images',
        icon: <PermMediaIcon />
    },
    {
        id: 3,
        name: "Формы",
        route: "/forms",
        icon: <FormatListNumberedIcon />
    },
    {
        id: 5,
        name: "Настройки",
        route: '/settings',
        icon: <SettingsApplicationsIcon />
    },
];

export default SidebarItems;