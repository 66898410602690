import SidebarItems from "./SidebarItems";
import { Link } from "react-router-dom";
import {Box, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  list: {
    justifyContent: "flex-start",
    alignItems: "baseline",
  },
  listItem: {
    color: "white",
    transition: 'all 0.25s ease-in-out',
    fontFamily: 'Roboto, sans-serif',
    paddingLeft: "24px !important",
    '&:hover': { backgroundColor: '#369142' },
  },
  route: {
    textDecoration: 'none',
    width: '100%'
  },
  icon: {
    color: 'white !important'
  }
}));

function Sidebar() {
  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const mobileViewStyles = {
    height: mdUp ? 'calc(100vh - 55px);' : 'auto',
    flexDirection: mdUp ? "column" : "row",
  }

  return (
    <Box>
        <List className={classes.list} style={mobileViewStyles}>
          {SidebarItems.map((item, index) => (
            <Link to={item.route} key={item.name} className={classes.route}>
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.icon} >
                {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name }/>
              </ListItem>
            </Link>
          ))}
        </List>
    </Box>
  );
}

export default Sidebar;