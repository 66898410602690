import './App.css';
import Layout from './components/Layout/Layout';
import React from "react";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Images from './components/Images/Images'
import Inputs from './components/Incoming/Incoming'
import Settings from "./components/Settings/Settings";
import Diet from './components/Diet/Diet';
import Login from './routes/auth/Login';
import Logout from './routes/auth/Logout';
import { Provider as SessionProvider } from './hooks/session';
import ProtectedRoute from './components/Layout/ProtectedRoute';
import FormList from './components/Form/FormList';
import Form from './components/Form/Form';

const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoute><Layout /></ProtectedRoute>,
    children: [
      { path: "/", element: <Inputs />},
      { path: "/incoming", element: <Inputs /> },
      { path: "/incoming/:formId", element: <Inputs /> },
      { path: "/images", element: <Images /> },
      { path: "/settings", element: <Settings /> },
      { path: "/diet/:formId", element: <Diet />},
      { path: "/forms", element: <FormList />},
      { path: "/forms/:formId", element: <Form />},
    ]
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'logout',
    element: <Logout />,
  }
]);

function App() {
  return (
    <SessionProvider>
          <RouterProvider router={router} />
    </SessionProvider>
  );
}

export default App;
