import React, { useState } from 'react';
import { TextareaAutosize, Box  } from '@mui/material';


const TextEditor = (props) => {
    const [editorState, setEditorState] = useState(props.defaultText);

    const handleEditorChange = (text) => {
        setEditorState(text);
        props.onChange(editorState);
    };

    return (
        <Box sx={{ width: '100%' }}>
        <TextareaAutosize
          value={editorState}
          onChange={(event) => handleEditorChange(event.target.value)}
          rowsMin={4} 
          rowsMax={10}
          style={{
            width: '100%',
            padding: '8px',
            resize: 'vertical',
            border: '1px solid #ccc',
            borderRadius: '4px',
            fontSize: '14px',
            lineHeight: '1.5',
            outline: 'none',
            marginBottom: '30px',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.43,
            padding: 30,
          }}
        />
      </Box>
    );
};

export default TextEditor;

